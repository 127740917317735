import consumer from "./consumer";

consumer.subscriptions.create("TimeChannel", {
  connected() {
		console.log('connected to time channel')
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
		console.log(data)
    // Called when there's incoming data on the websocket for this channel
		// const timeContainer = document.querySelector('#time');
    // if (timeContainer) {
    //   timeContainer.innerHTML = data.time;
    // }
  }
});
